import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/RZtQW9yufQM"
    bibleGroupSrc="https://www.docdroid.net/3tWtcMt/bible-group-homework-7-12-pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/23943904"
  >
    <SEO title="The Christian Social Media Guide - Colossians" />
  </Layout>
)

export default SermonPost
